import React from "react";

import SolutionView from "domains/Solutions";
import SEO from "components/seo";

function SolutionPage() {
  return (
    <>
      <SEO
        title="Vehicle Detection and Recognition"
        description="Solve mobility issues in your area. Enhance your existing CCTV to identify license plate numbers, count and classify vehicles, detect illegal parking, and more!"
      />
      <SolutionView title="vehicle detection and recognition" />
    </>
  );
}

export default SolutionPage;
